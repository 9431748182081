const initialState = {

    reportValues: {
        "firstname": "Joe",
        "lastname": "Clean",
        "dateofbirth": "1992-10-22",
        "email": "mkureth@gmail.com"
    },
    formValues: {
        "id": "333-33-3333",
        "application_id": "",
        "information_applicant_firstname": "Joe",
        "information_applicant_middlename": "M",
        "information_applicant_lastname": "Clean",
        "information_applicant_dateofbirth": "1992-10-22",
        "information_applicant_socialsecuritynumber": "111-22-3333",
        "information_applicant_gender": {
          "name": "MALE",
          "code": "MALE"
        },
        "information_applicant_email": "mkureth@gmail.com",
        "information_applicant_cellphonenumber": "1-310-916-6452",
        "information_applicant_driverslicensenumber": "123456789",
        "information_applicant_driverslicensestate": {
          "name": "AZ",
          "code": "AZ"
        },
        "information_landlord_fullname": "Jane Doe",
        "information_landlord_email": "heather.naylor@gmail.com",
        "information_landlord_phonenumber": "1-111-111-1111",
        "information_landlord_referral": "",
        "address_present_address1": "2nd North Circle",
        "address_present_address2": "SUITE 7027",
        "address_present_city": "Phoenix",
        "address_present_state": {
          "name": "AZ",
          "code": "AZ"
        },
        "address_present_zipcode": "11111",
        "address_present_reasonforleaving": "due to covids",
        "address_present_dateoccupiedfrom": "2010-11-04",
        "address_present_dateoccupiedto": "2012-10-06",
        "address_present_noticegiven": {
          "name": "YES",
          "code": "YES"
        },
        "address_present_rentamount": "1250.5",
        "address_present_depositamount": "500",
        "address_present_landlordfullname": "Jessica Jones",
        "address_present_landlordemail": "jessica@jones.com",
        "address_present_landlordphonenumber": "1-310-916-6452",
        "address_previous_address1": "",
        "address_previous_address2": "",
        "address_previous_city": "",
        "address_previous_state": "",
        "address_previous_zipcode": "",
        "address_previous_reasonforleaving": "",
        "address_previous_dateoccupiedfrom": null,
        "address_previous_dateoccupiedto": null,
        "address_previous_rentamount": 0,
        "address_previous_landlordfullname": "",
        "address_previous_landlordemail": "",
        "address_previous_landlordphonenumber": "",
        "income_current_companyname": "Disney",
        "income_current_companycity": "Burbank",
        "income_current_companystate": {
          "name": "CA",
          "code": "CA"
        },
        "income_current_companyzipcode": "90230",
        "income_current_dateemployedfrom": "2018-06-02T00:02:00.000Z",
        "income_current_dateemployedto": "2020-05-13T00:02:00.000Z",
        "income_current_companysupervisorname": "John Smith",
        "income_current_companysupervisorphonenumber": "1-222-222-2222",
        "income_current_companyposition": "Astronomer",
        "income_current_companysalary": "5000",
        "income_current_otherincome": "",
        "income_current_otheramount": 0,
        "income_current_otherdescribe": "",
        "income_past_companyname": "",
        "income_past_companycity": "",
        "income_past_companystate": "",
        "income_past_companyzipcode": "",
        "income_past_dateemployedfrom": null,
        "income_past_dateemployedto": null,
        "income_past_companysupervisorname": "",
        "income_past_companysupervisorphonenumber": "",
        "income_past_companyposition": "",
        "income_past_companysalary": 0,
        "authorization_initials": "",
        "authorization_fullname": "",
        "authorization_signature": "",
        "authorization_signeddate": null,
        "services_products":["CRED0001"],
        "payment_complete": false,
        "signature_complete": false,
        "order_transaction_id": null,
        "order_transaction_amount": null,
        "order_transaction_customer": null,
    },
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'editFormValue':
            state.formValues[action.key.toLowerCase()] = action.value;
            return { ...state };

        case 'editReportValue':
            state.reportValues[action.key.toLowerCase()] = action.value;
            return { ...state };

        case 'emptyFormValue':
            return {
                ...state,
                formValues: initialState.formValues
            };
        default:
    };
    return state;
};

export { initialState, reducer }