import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";


import {
  Button, 
  AppBar, 
  Toolbar, 
  Typography,
  Box,
  ButtonGroup
} from '@material-ui/core';

import { StateProvider } from './StateContext';
import { ThemeProvider } from '@material-ui/styles';
import theme from './constants/theme';

import Footer from "./Views/Footer";
import LegalNoticePopup from "./Views/LegalNoticePopups/LegalNoticePopup";


import Application from "./Views/Application";
import Home from "./Views/Home";
import Reports from "./Views/Reports";
import PrivacyPolicy from "./Views/PrivacyPolicy";
import TermsOfUse from "./Views/TermsOfUse";


import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  logo: {
    height: '25px',
    width: '100px',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export default function App() {
  const classes = useStyles();

  return (
    <Router>

      <AppBar position="static" color="default" className={classes.appBar}>
        <Toolbar>
          <Box display="flex" flexGrow={1}>
            <Typography variant="h6" color="inherit" noWrap edge="start">
              <Link to="/">
                <img 
                  className={classes.logo}
                  alt="Qualofied"
                  src={'https://qualofied.com/assets/social/qualofied-logo.png'}
                />
              </Link>
            </Typography>
          </Box>
          <ButtonGroup color="default" aria-label="primary button group">
            <Button component={Link} to="/application">
              Application
            </Button>
            <Button component={Link} to="/reports">
              Reports
            </Button>
          </ButtonGroup>
        </Toolbar>
      </AppBar>

      <ThemeProvider theme={theme}>
        <StateProvider>
          <div style={{ flexGrow: 1 }}>
            <Switch>
              <Route path="/application">
                <Application />
              </Route>
              <Route path="/reports">
                <Reports />
              </Route>
              <Route path="/privacy-policy">
                <PrivacyPolicy />
              </Route>
              <Route path="/terms-of-use">
                <TermsOfUse />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
            <Footer />
          </div>
          <LegalNoticePopup />
        </StateProvider>
      </ThemeProvider>
      
    </Router>
  );
}